.intro {
  min-height: 100vh;
  background-color: #b9ffee;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23fffab1' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%236f6f36' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23fff8aa' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%2374733d' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23fff5a2' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23797843' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23fff39b' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%237e7c4a' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23fff193' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23838150' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23ffee8c' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23888557' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  /* background-attachment: fixed; */
  background-size: cover;
  position: relative;
}

.hieroglyph {
  height: auto;
  line-height: normal;
  font-size: 100px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.key {
  height: auto;
  line-height: normal;
  font-size: 30px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.keyDesc {
  width: 70%;
  font-size: 30px;
  font-family: 'Macondo', cursive;
  text-align: center;
  background-color: rgba(238, 204, 140, 0.8);
  margin: 30px auto 0 auto;
  margin-bottom: 0;
}

#camelImage1 {
  position: absolute;
  bottom: 0px;
  left: 80%;
}

#camelImage2 {
  position: absolute;
  bottom: 0px;
  left: 40%;
  transform: scaleX(-1);
}

.contentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-content: center;
  justify-content: center;
  margin: 0px auto;
}

.title {
  padding-top: 50px;
  font-size: 30px;
  font-family: 'Macondo', cursive;
}

.translate {
  margin: 0px;
  font-size: 60px;
  font-family: 'Macondo', cursive;
  text-align: center;
}

.btn {
  font-size: 30px;
  align-self: center;
  width: auto;
  font-family: 'Macondo', cursive;
  background-color: #eecb8c;
  border: 1px solid black;
  border-radius: 12px;
  margin: 5px;
}

.btn:hover {
  cursor: pointer;
  background-color: #a88f5e;
}

.translator {
  min-height: 100vh;
  background-color: #a05a2c;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23d9b59e' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.input {
  width: 50%;
  height: 50px;
  font-size: 30px;
  align-self: center;
  font-family: 'Macondo', cursive;

  background-color: #eecb8c;
  border-radius: 5%;
  opacity: 85%;
}

#translatedContent {
  width: 80%;
  height: 350px;
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: #eecb8c;
  border-radius: 5%;
  opacity: 85%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;

  overflow: scroll;
}

#keyContent {
  width: 80%;
  height: 200px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;

  margin: 10px auto;

  overflow: scroll;

  background-color: #eecb8c;
  
  border-radius: 5%;
  opacity: 85%;
}

.divide {
  min-height: 10vh;
}

.imageContainer {
  background: rgb(160, 90, 44);
  background: linear-gradient(
    0deg,
    rgba(160, 90, 44, 1) 86%,
    rgba(216, 184, 130, 1) 95%,
    rgba(218, 212, 146, 1) 100%
  );
}

.image {
  width: 100%;
}

#translatedText {
  font-size: 70px;
  margin: 15px;
}

@media only screen and (min-width: 300px)  and (max-width: 500px){
  .keyDesc {
    font-size: 15px;
    font-family: 'Macondo', cursive;
    text-align: left;
    background-color: rgba(238, 204, 140, 0.8);
    margin-top: 40px;
    margin-bottom: 0;
  }

  #camelImage1 {
    position: absolute;
    bottom: 0px;
    left: 70%;
  }

  #camelImage2 {
    position: absolute;
    bottom: 0px;
    left: 10%;
    transform: scaleX(-1);
  }

  .translate {
    margin-bottom: 10px;
    font-size: 30px;
  }

  #translatedContent {
    height: 100px;
    width: 80%;
    margin: 0 auto;
    overflow: scroll;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
  }

  #keyContent {
    width: 80%;
    height: 70px;

    margin-top: 20px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;

    overflow: scroll;

    background-color: #eecb8c;
    border-radius: 5%;
    opacity: 85%;
  }

  .input {
    margin: 0px auto;
    height: 25px;
    width: 80%;
    font-size: 20px;
  }

  .btn {
    width: auto;
    height: auto;

    font-size: 20px;
    margin: 10px auto;
  }

  .hieroglyph {
    height: auto;
    line-height: normal;
    font-size: 30px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .key {
    height: auto;
    line-height: normal;
    font-size: 15px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 10px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 800px) {

  #camelImage1 {
    position: absolute;
    bottom: 0px;
    left: 80%;
  }
  
  #camelImage2 {
    position: absolute;
    bottom: 0px;
    left: 40%;
    transform: scaleX(-1);
  }
  .translate {
    font-size: 40px;
    margin-top: 80px;
  }

  #translatedContent {
    height: 200px;
    width: 80%;
    margin: 30px auto 0 auto;
    overflow: scroll;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
  }

  .keyDesc {
    font-size: 15px;
    font-family: 'Macondo', cursive;
    text-align: center;
    background-color: rgba(238, 204, 140, 0.8);
    margin: 40px auto 0 auto;
  }

  #keyContent {
    width: 80%;
    height: 120px;

    margin-top: 10px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;

    overflow: scroll;

    background-color: #eecb8c;
    border-radius: 5%;
    opacity: 85%;
  }

  .input {
    margin-top: 20px;
    height: 25px;
    width: 80%;
    font-size: 40px;
  }
  .hieroglyph {
    height: auto;
    line-height: normal;
    font-size: 40px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .key {
    height: auto;
    line-height: normal;
    font-size: 20px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 10px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 2000px) {
  #camelImage1 {
    position: absolute;
    bottom: 0px;
    left: 70%;
  }
  
  #camelImage2 {
    position: absolute;
    bottom: 0px;
    left: 40%;
    transform: scaleX(-1);
  }

  .translate {
    font-size: 40px;
    margin-top: 10px;
  }

  #translatedContent {
    height: 250px;
    width: 80%;
    margin: 10px auto 0 auto;
    overflow: scroll;
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  #keyContent {
    width: 80%;
    height: 280px;

    margin-top: 10px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;

    overflow: scroll;

    background-color: #eecb8c;
    border-radius: 5%;
    opacity: 85%;
  }

  .input {
    margin-top: 0px;
    height: 40px;
    width: 80%;
    font-size: 30px;
  }

  .btn {
    width: auto;
    height: auto;
    margin: 30px 0 0 0;
    font-size: 25px;
  }
  .hieroglyph {
    height: auto;
    line-height: normal;
    font-size: 60px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .key {
    height: auto;
    line-height: normal;
    font-size: 30px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 10px;
  }

  .keyDesc {
    margin: 10px auto 0 auto;
  }
}

